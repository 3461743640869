<template>
  <div>
    <modal
      ref="modal"
      :class-name="[
        'duration-300 flex flex-col p-0 rounded text-center w-full', modalWidth
      ]"
      :content-style="{ height: modalHeight }"
    >
      <transition name="slide-fade" mode="out-in">
        <component
          :is="component"
          @close="close"
          @next="navigate"
          @request-card="completeCardRequest"
          @mounted="emitComponentMountedEvent"
          @update-plans="updatePlans"
        />
      </transition>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'CardRequest',
  components: {
    Notice: () => import('./Notice'),
    Subscription: () => import('./Subscription'),
    Success: () => import('./Success'),
    Welcome: () => import('./Welcome'),
  },
  data() {
    return {
      stage: 1,
      plans: [],
    };
  },
  computed: {
    canAccessPrestigePlan() {
      return this.user?.profile?.['credit_limit'] >= 100000;
    },
    component() {
      switch (this.stage) {
        case 4:
          return 'Success';
        case 3:
          return 'Subscription';
        case 2:
          return 'Notice';
        case 1:
        default:
          return 'Welcome';
      }
    },
    modalWidth() {
      switch (this.stage) {
        case 4:
          return 'md:w-500px';
        case 3:
          return 'md:w-500px';
        case 2:
          return 'md:w-500px';
        case 1:
        default:
          return 'md:w-600px 2xl:w-700px';
      }
    },
    modalHeight() {
      switch (this.stage) {
        case 4:
          return `${500}px`;
        case 3: {
          const length = this.plans.length || 1;
          const planHeight = 120;
          const gapHeight = 22.5;
          const baseHeight = 300;
          const height = baseHeight + (planHeight * length) + (gapHeight * (length - 1));
          return `${height}px`;
        }
        case 2:
          return `${600}px`;
        case 1:
        default:
          return `${550}px`;
      }
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    completeCardRequest() {
      this.close();
      this.$emit('request-card');
    },
    emitComponentMountedEvent() {
      this.$emit('mounted');
    },
    navigate() {
      this.stage++;
    },
    open() {
      this.$refs.modal.open();
    },
    updatePlans(plans = []) {
      this.plans = plans;
    }
  },
};
</script>
